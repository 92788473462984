<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleMethod()"
      >
        <v-card-title class="headline">
          {{ isEdit ? "Editar" : "Nueva" }} antena
        </v-card-title>
        <v-card-text>
          <p class="text-subtitle-2 font-weight-medium mb-2">
            Nombre de antena
          </p>
          <v-text-field
            v-model="antennaName"
            :rules="required"
            class="rounded-0"
            dense
            height="50"
            placeholder="Escriba el nombre de la antena"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="px-3"
            depressed
            outlined
            color="primary"
            @click="$emit('cancel')"
          >
            REGRESAR
          </v-btn>
          <v-btn
            class="px-6"
            type="submit"
            :loading="loading"
            depressed
            color="primary"
          >
            {{ isEdit ? "ACTUALIZAR" : "GUARDAR" }} ANTENA
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  //Domians
  import Antenna from "../../domain/Antennas";

  export default {
    mounted() {
      if (this.isEdit) this.antennaName = this.antennaEdit.antennaName;
    },
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
      isEdit: {
        type: Boolean,
        required: true,
      },
      antennaEdit: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      valid: true,
      antennaName: "",
      required: [(v) => !!v || "El campo es requerido"],
      loading: false,
    }),
    methods: {
      async saveAntenna() {
        try {
          if (!this.$refs.form.validate()) return false;
          this.loading = true;
          if (this.isEdit) {
            this.editAntenna();
            return true;
          }
          const antenna = { antennaName: this.antennaName };
          await Antenna.create(antenna);
          this.$emit("refreshView");
        } catch (error) {
          console.warn(error);
        } finally {
          this.loading = false;
        }
      },
      async editAntenna() {
        try {
          const antenna = { antennaName: this.antennaName };
          await Antenna.update(this.antennaEdit._id, antenna);
          this.$emit("refreshView");
        } catch (error) {
          console.warn(error);
        }
      },
      handleMethod() {
        if(this.isEdit) this.editAntenna()
        else this.saveAntenna();
      },
    },
  };
</script>
